import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';

import {BackendClient, HttpBackendClient} from '@meal-planner/backend';
import {SessionService, SessionServiceImpl} from '@meal-planner/session';

import {providePathResolver} from '../backend/module';

import {SessionBackendPathResolver} from './session-path-resolver';

export const SESSION_SERVICE_TOKEN = new InjectionToken<SessionService>(
  'SESSION_SERVICE'
);

export function createSessionService(
  client: BackendClient
): SessionServiceImpl {
  return new SessionServiceImpl(client);
}

@NgModule()
export class SessionModule {
  public static forRoot(): ModuleWithProviders<SessionModule> {
    return {
      ngModule: SessionModule,
      providers: [
        {
          provide: SESSION_SERVICE_TOKEN,
          useFactory: createSessionService,
          deps: [HttpBackendClient],
        },
        providePathResolver(SessionBackendPathResolver),
      ],
    };
  }
}
