import {BackendHttpRequest, Call, PathResolver} from '@meal-planner/backend';
import {Injectable} from '@angular/core';

@Injectable()
export class SessionBackendPathResolver implements PathResolver {
  resolve(call: Call<unknown>): BackendHttpRequest | null {
    if (call.target.type === 'session') {
      return {
        host: '/api',
        path: 'session/' + call.target.service,
      };
    }

    return null;
  }
}
