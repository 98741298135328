import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BackendModule} from './backend/module';
import {SessionModule} from './session/session.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular
    BrowserModule,

    // Meal Planner
    AppRoutingModule,
    BackendModule,
    SessionModule.forRoot(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
