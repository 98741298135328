export interface BackendHttpRequest {
  readonly host: string;
  readonly path: string;
}

export interface BackendHttpResponse<T> {
  readonly status: number;
  readonly body: T | null;
}

export class BackendHttpError extends Error {
  public constructor(
    public readonly status: number,
    public readonly body: unknown | null
  ) {
    super();
  }
}

export interface CallTarget {
  readonly type: string;
  readonly service: string;
}

export interface Call<O> {
  readonly target: CallTarget;
  convertOutput(output: unknown): O;
}

export abstract class SchemaCall<O> implements Call<O> {
  abstract target: CallTarget;

  public constructor(
    private readonly outputValidator: (output: unknown) => output is O
  ) {}

  public convertOutput(output: unknown): O {
    if (this.outputValidator(output)) {
      return output;
    }

    throw new Error();
  }
}
