import {
  BackendHttpError,
  BackendHttpRequest,
  BackendHttpResponse,
  BackendTransport,
} from '@meal-planner/backend';
import {
  HttpClient,
  HttpErrorResponse,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class NgBackendTransport implements BackendTransport {
  public constructor(private readonly http: HttpClient) {}

  public send(
    request: BackendHttpRequest
  ): Observable<BackendHttpResponse<unknown>> {
    const url = `${request.host}/${request.path}`;
    const ngRequest = new HttpRequest('POST', url, {});

    return this.http.request(ngRequest).pipe(
      filter((event) => event instanceof HttpResponse),
      map((event) => event as HttpResponse<unknown>),
      catchError((error: HttpErrorResponse) =>
        throwError(() => new BackendHttpError(error.status, error.error))
      )
    );
  }
}
