import {InjectionToken, NgModule, Provider, Type} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {HttpBackendClient, PathResolver} from '@meal-planner/backend';

import {NgBackendTransport} from './transport';

const PATH_RESOLVER = new InjectionToken<PathResolver[]>('');

export function createHttpBackendClient(
  transport: NgBackendTransport,
  resolvers: PathResolver[]
) {
  return new HttpBackendClient(transport, resolvers);
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    NgBackendTransport,
    {
      provide: HttpBackendClient,
      deps: [NgBackendTransport, PATH_RESOLVER],
      useFactory: createHttpBackendClient,
    },
  ],
})
export class BackendModule {}

export function providePathResolver(type: Type<PathResolver>): Provider {
  return {
    provide: PATH_RESOLVER,
    useClass: type,
    multi: true,
  };
}
