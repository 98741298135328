import {BackendHttpRequest, Call} from './model';
import {Observable, throwError} from 'rxjs';
import {BackendTransport} from './transport';
import {map} from 'rxjs/operators';

export interface PathResolver {
  resolve(call: Call<unknown>): BackendHttpRequest | null;
}

export interface BackendClient {
  invoke<O>(call: Call<O>): Observable<O>;
}

export class HttpBackendClient implements BackendClient {
  public constructor(
    private readonly transport: BackendTransport,
    private readonly resolvers: PathResolver[]
  ) {}

  public invoke<O>(call: Call<O>): Observable<O> {
    const request = this.resolvers.reduce(
      (result: BackendHttpRequest | null, resolver: PathResolver) => {
        return result ?? resolver.resolve(call);
      },
      null
    );

    if (request == null) {
      return throwError(() => new Error('No request'));
    }

    return this.transport
      .send(request)
      .pipe(map((response) => call.convertOutput(response.body)));
  }
}
