import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, take} from 'rxjs';

import {AuthenticationService} from '@rombo/shared/authentication-angular/data-access';

@Injectable({providedIn: 'root'})
export class IsAuthenticatedGuard implements CanActivate {
  public constructor(
    private readonly authenticationData: AuthenticationService
  ) {}

  public canActivate(): Observable<boolean> {
    return this.authenticationData.isAuthenticated$.pipe(take(1));
  }
}

@Injectable({providedIn: 'root'})
export class IsAnonymousGuard implements CanActivate {
  public constructor(
    private readonly authenticationData: AuthenticationService
  ) {}

  public canActivate(): Observable<boolean> {
    return this.authenticationData.isAnonymous$.pipe(take(1));
  }
}
