export interface Session {
  active: boolean;
}

export interface InactiveSession extends Session {
  active: false;
}

export const isInactiveSession = (
  session: Session
): session is InactiveSession => session.active === false;

export interface ActiveSession extends Session {
  active: true;
}

export const isActiveSession = (session: Session): session is ActiveSession =>
  session.active === true;
