import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {OperatorFunction, ReplaySubject, filter, map} from 'rxjs';

import {
  Authentication,
  AuthenticationService,
} from '@rombo/shared/authentication/data-access';

@Injectable({providedIn: 'root'})
export class AuthenticationAngularService implements AuthenticationService {
  private readonly authenticationSubject =
    new ReplaySubject<Authentication | null>(1);

  public readonly authentication$ = this.authenticationSubject
    .asObservable()
    .pipe(
      filter((value) => !!value) as OperatorFunction<
        Authentication | null,
        Authentication
      >
    );
  public readonly isAuthenticated$ = this.authenticationSubject
    .asObservable()
    .pipe(map((value) => value != null));

  public readonly isAnonymous$ = this.authenticationSubject
    .asObservable()
    .pipe(map((value) => value == null));

  public constructor(client: HttpClient) {
    client
      .get<Authentication>('/api/authentication', {
        observe: 'body',
        responseType: 'json',
      })
      .subscribe({
        next: (value) =>
          this.authenticationSubject.next({username: value.username}),
        error: () => this.authenticationSubject.next(null),
      });
  }

  public signout() {
    this.authenticationSubject.next(null);
  }
}
