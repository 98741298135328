import {BackendClient, Call, SchemaCall} from '@meal-planner/backend';
import {Observable, of} from 'rxjs';
import {catchError, mapTo} from 'rxjs/operators';

import {Session} from './model';

const target = (service: string) => ({type: 'session', service});

class RefreshSessionCall extends SchemaCall<Record<string, never>> {
  public readonly target = target('refresh');

  public constructor() {
    super((output: unknown): output is Record<string, never> => {
      return typeof output === 'object';
    });
  }
}

class EndSessionCall implements Call<void> {
  public readonly target = target('end');

  convertOutput(): void {
    return;
  }
}

export interface SessionService {
  refresh(): Observable<Session>;
  end(): Observable<void>;
}

export class SessionServiceImpl implements SessionService {
  public constructor(private readonly client: BackendClient) {}

  public refresh(): Observable<Session> {
    return this.client.invoke(new RefreshSessionCall()).pipe(
      mapTo({active: true}),
      catchError(() => of({active: false}))
    );
  }

  public end(): Observable<void> {
    return this.client.invoke(new EndSessionCall());
  }
}
