import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {
  IsAnonymousGuard,
  IsAuthenticatedGuard,
} from '@rombo/shared/authentication-angular/feature';

@NgModule({
  exports: [RouterModule],
  imports: [
    // Angular
    RouterModule.forRoot([
      {
        path: '',
        canActivate: [IsAuthenticatedGuard],
        children: [
          {path: '', redirectTo: 'home', pathMatch: 'full'},
          {
            path: 'home',
            loadChildren: () =>
              import('./authenticated/home/home.module').then(
                (m) => m.HomeModule
              ),
          },
        ],
      },
      {
        path: 'login',
        canActivate: [IsAnonymousGuard],
        loadChildren: () =>
          import('./public/login/login-module.module').then(
            (m) => m.LoginModuleModule
          ),
      },
    ]),
  ],
})
export class AppRoutingModule {}
